<template>
    <form class="">
        <div class="vx-row">
            <div class="vx-col mb-5 w-full">
                <vs-input type="text" v-validate="{ required: true}" v-model="data.name"
                id="inputName" label="Name" name="name" class="w-full" :class="{ 'is-invalid': errors.has('name') }"
                placeholder="Enter name" />
                <span class="text-danger small"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
            <div class="vx-col mb-5 w-full">
                <vs-input type="text" v-validate="{ required: !is_photo_gallery}" v-model="data.value"
                id="inputValue" label="Value" name="value" class="w-full" :class="{ 'is-invalid': errors.has('value') }"
                placeholder="Enter url or color codes" />
                <span class="text-danger small"  v-show="errors.has('value')">{{ errors.first('value') }}</span>
                <div>
                    <small @click="is_photo_gallery = !is_photo_gallery" >
                        <span v-if="!is_photo_gallery" class="text-success cursor-pointer">Click to add file</span>
                        <span v-else class="text-warning cursor-pointer">Click to hide uploader</span>
                        for photo gallery
                    </small>
                </div>
            </div>
            <div class="vx-col mb-5 w-full">
                <vs-input type="text" v-model="data.description"
                    name="description" label="Description" class="w-full"
                    placeholder="Write description" />
            </div>
            <div class="vx-col mb-2 w-full">
                <span class="mr-5 md:mr-8">
                    <span class="text-sm">Default</span>
                    <vx-tooltip text="Should select by default" position="right">
                        <vs-switch v-model="data.default">
                        <span slot="on">On</span>
                        <span slot="off">Off</span>
                        </vs-switch>
                    </vx-tooltip>
                </span>
            </div>
            <div v-if="is_photo_gallery" class="vx-col mb-5 w-full">
                <vue-dropzone id="reqValeUploadDrop" ref="reqValeUploadDrop"
                :use-custom-slot="true" :maxFiles="1"
                    acceptedFiles="image/*,.webp">
                    <div class="needsclick m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                        <h4 class="mb-2"> {{editMode? 'Add to update image (photo gallery)':'Drop file or click to add'}}</h4>
                        <p><code>500px</code> x <code>500px</code> dimension recommended </p>
                    </div>
                </vue-dropzone>
            </div>
            <div class="vx-col w-full">
                <div class="d-flex align-items-center justify-content-end">
                    <vs-button class="mr-2" color="primary" 
                    @click.prevent="editMode? update() : create()"
                     size="small">{{editMode? 'Update':'Submit'}}</vs-button>
                     <vs-button color="danger" 
                    @click.prevent="$emit('exit')"
                     size="small" >Exit Form</vs-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import VueDropzone from "@/components/VueDropzone.vue";

export default {
    components:{
        VueDropzone
    },
    props:{
        value:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        requirementId:{
            type: Number,
            default: false
        }
    },
    data(){
        return{
            is_photo_gallery: false,
            data: {
                requirement_id: this.requirementId,
                name: "",
                value: "",
                default: false,
                description: "",
            },
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                 if(this.is_photo_gallery){
                    if(!this.collectFiles().length){this.alertError("add image file"); return;}
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post(`/product-requirement-values/create`, formData)
                .then((response) => {
                    this.$vs.loading.close()
                    if(response.data.success){
                        this.$emit("created", response.data.data.value)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                if(this.is_photo_gallery && this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post(`/product-requirement-values/${this.value.id}/update`,formData)
                    .then((response) => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$emit("updated", response.data.data.value)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.reqValeUploadDrop){
                arrafile = [...this.$refs.reqValeUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.$validator.reset()
        }
    },
    mounted(){
        if(this.editMode){
            this.data = {...this.value}
        }
    }
}
</script>