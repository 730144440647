<template>
  <vx-card no-shadow>
    <vs-list>
      <vs-list-header icon-pack="feather" icon="icon-layers" title="Product Requirements">
      </vs-list-header>
    </vs-list>
    <vs-collapse type="default" class="p-0" accordion>
        <vs-collapse-item v-for="(requirement, index) in requirements" :key="index">
          <div slot="header">{{requirement.title}}</div>
          <div>
            <p><strong> Type:</strong> {{inputTypes[requirement.type]}}</p>
            <p><strong> Title</strong> {{requirement.title}}</p>
            <p><strong> Required:</strong> {{requirement.required}}, <strong> multiple:</strong> {{requirement.multiple}}</p>
            <p><strong> Description:</strong> {{requirement.description}}</p>
          </div>
          <div class="flex mt-5">
            <vs-button radius color="danger" @click="deleteItem(requirement.id)" class="inline" type="border" icon-pack="feather" icon="icon-trash"></vs-button>
            <vs-button radius color="dark" @click="editItem(requirement)"  class="inline ml-2" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
            <vs-button class="ml-2" @click="showValues(requirement)" size="small">View values</vs-button>
          </div>
        </vs-collapse-item>
    </vs-collapse>

    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="mt-2 mr-2" @click="popupClone = true;" color="warning" size="small">Copy from</vs-button>
      <vs-button class="mt-2" @click="popupAddItem = true; editMode = false " size="small">Add Requirement</vs-button>
    </div>

    <vs-popup class="holamundo" @close="initializeAll"
     :title="editMode ? 'Edit Requirement':'Add Requirement'" 
     :active.sync="popupAddItem">
      <product-requirement-form v-if="popupAddItem"
        :editItem="showItem"
        :editMode="editMode"
        @closeMe="initializeAll"/>
    </vs-popup>

    <vs-popup class="holamundo" @close="initializeAll"
     title="Requirement Values" 
     :active.sync="popupModalValues">
      <product-requirement-values
        v-if="popupModalValues"
       :showItem="showItem"/>
    </vs-popup>

    <vs-popup class="holamundo" @close="initializeAll"
     title="Select product to copy from" 
     :active.sync="popupClone">
       <select v-model="cloneFrom"
          class="form-select mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, dpIndex) in products" :key="dpIndex"
          :value="dPro.id" >{{dPro.name}}</option>
      </select>
      <vs-button class="mt-2" @click="cloneNow()" size="small">Clone</vs-button>
    </vs-popup>
  </vx-card>
</template>
<script>
import ProductRequirementForm from "@/components/forms/ProductRequirementForm.vue" 
import ProductRequirementValues from "./ProductRequirementValues.vue"
export default {
  components:{
    ProductRequirementForm,
    ProductRequirementValues
  },
  data() {
    return {
      popupAddItem: false,
      popupModalValues: false,
      editMode: false,
      showItem: null,
      cloneFrom: null,
      popupClone: false
    }
  },
  computed: {
    requirements(){
      return this.$store.state.productList.product.requirements
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
    inputTypes(){
      return this.$store.state.productList.inputTypes
    }
  },
  methods: {
    editItem(item){
      this.editMode = true
      this.showItem =  {...item}
      this.popupAddItem = true
    },
    showValues(item){
      this.showItem = {...item}
      this.popupModalValues = true  
    },
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'You want to delete requirement!',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.delete(`/product-requirements/${itemId}/delete`)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("productList/DELETE_REQUIREMENT", itemId)
        }
      })
    },
    cloneNow(){
      if(!this.cloneFrom){return}
      let data = {from: this.cloneFrom, to: this.$route.params.productId}
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post('/product-requirements/clone', data)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct", this.$route.params.productId)
          this.initializeAll()
        }
      })
    },
    initializeAll(){
      this.showItem = null
      this.editMode = false
      this.popupAddItem = false
      this.popupModalValues = false
      this.popupClone = false;
      this.cloneFrom = null
    }
  }
}
</script>
