<template>
  <div>
    <div class="vx-row">
      <div class="vx-col sm:w-2/3 w-full">
        <vx-card subtitle="Portfolio Images"  class="card-reduce-top-pad carousel-example">
          <template slot="actions">
            <vs-dropdown>
              <a class="flex items-center" href="#">
                <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
              </a>
              <vs-dropdown-menu style="min-width:100px;">
                <vs-dropdown-item @click="popupSlideUploader = true">
                  Add&nbsp;Image
                </vs-dropdown-item>
                <vs-dropdown-item @click="popupSlideUploader = true; isVideoFile = true">
                  Add&nbsp;Video
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <swiper
          :options="swiperOption"
          :auto-update="true"
          :auto-destroy="true"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          :key="$vs.rtl">
            <swiper-slide v-for="(slide, imageIndex) in portfolios" :key="slide.id">
              <div class="w-100 h-100 swiper">
                <div v-if="slide.is_video">
                  <span @click="lightboxIndex = imageIndex" class="play-button">
                     <feather-icon icon="PlayIcon" svgClasses="w-12 h-6 text-white"></feather-icon>
                  </span>
                  <img class="responsive slide-img" :src="absoluteUrl(slide.path)" :alt="slide.image">
                </div>
                <div v-else>
                  <img @click="lightboxIndex = imageIndex" class="responsive slide-img" :src="absoluteUrl(slide.path)" :alt="slide.image">
                </div>
              </div>
              <ul class="list list-settings flex">
                <li class="list__item" >
                  <vs-button radius color="dark" class="inline" type="line" icon-pack="feather" @click="editPortfolio(slide.id)" icon="icon-edit"></vs-button>
                </li>
                <li class="list__item" >
                  <vs-button radius color="dark" class="inline" type="line" icon-pack="feather" @click="confirmPortfolioDelete(slide.id)" icon="icon-trash"></vs-button>
                </li>
              </ul>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          </swiper>
        </vx-card>
      </div>
    </div>
    <vs-popup
      @close="initializeAll"
      fullscreen
      :title="isVideoFile? 'Upload Video Files':'Add New Portfolios'"
      :active.sync="popupSlideUploader">
      <div>
        <upload-video @closeModal="initializeAll()" v-if="isVideoFile" />
        <upload-images @closeModal="initializeAll()" v-else />
      </div>
    </vs-popup>

    <vs-prompt
      @cancel="initializeAll"
      @accept="sliderEditMode ? updatePortfolio() : updateProduct()"
      @close="initializeAll"
      :title="sliderEditMode ? 'Update Slider Image': 'Update Primary Image'"
      :is-valid="validReader"
      :active.sync="promptUpdate">
      <div class="con-exemple-prompt">
        <template>
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="imageHolderOnUpdate" v-if="imageHolderOnUpdate" alt="img" class="responsive">
          </div>
          <input type="file" class="hidden" ref="fileInputUpload" @change="fileInputReader" accept="image/*">
          <div class="modify-img flex justify-between mt-5" v-if="imageHolderOnUpdate">
            <vs-button class="mr-4" size="small" type="flat" @click="$refs.fileInputUpload.click()">Update Image</vs-button>
            <vs-button type="flat"  size="small" color="#999" @click="removeImage()">Remove Image</vs-button>
          </div>
          <div class="upload-img mt-5 text-center" v-if="!imageHolderOnUpdate">
            <vs-button size="small" @click="$refs.fileInputUpload.click()">Upload Image</vs-button>
          </div>
        </template>
      </div>
    </vs-prompt>

     <CoolLightBox
      :items="lightboxFiles"
      :index="lightboxIndex"
      :useZoomBar="true"
      :fullScreen="true"
      @close="lightboxIndex = null">
    </CoolLightBox>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import CoolLightBox from 'vue-cool-lightbox'
import UploadImages from './UploadImages.vue'
import UploadVideo from './UploadVideo.vue'

export default {
  components:{
    swiper,
    swiperSlide,
    CoolLightBox,
    UploadImages,
    UploadVideo
  },
  data() {
    return {
      lightboxIndex: null,
      imageHolderOnUpdate: "",
      fileEditPrimary: null,
      fileEditSlider: null,

      popupSlideUploader:false,

      sliderEditMode: false,
      awaitingDelete: null,
      awaitingEdit: null,

      promptUpdate:false,

      swiperOption: {
        spaceBetween: 30,
        effect: 'fade',
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      isVideoFile: false,
    }
  },
  computed: {
    validReader(){
      return this.imageHolderOnUpdate ? true : false
    },
    product(){
      return this.$store.state.productList.product;
    },
    portfolios(){
      return this.product.portfolios;
    },
    lightboxFiles(){
      return this.portfolios.length ?
      this.portfolios.map(img => {
        if(img.is_video){
          return {
            title: img.image,
            thumb: this.absoluteUrl(img.path),
            mediaType: 'video',
            src: this.absoluteUrl(img.video_path)
          }
        }else{
          return {
            title: img.image,
            mediaType: 'image',
            src: this.absoluteUrl(img.path)
          }
        }
      }):[]
    }
  },
  methods: {
    updatePortfolio(){
      if(!this.imageHolderOnUpdate || !this.awaitingEdit){return}
      let formData = new FormData();
      formData.append('image', this.fileEditSlider)
      this.$vs.loading({ color: "#444", type:'material' })
      this.$http.post(`/portfolios/${this.awaitingEdit}/update`,formData)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("productList/UPDATE_PORTFOLIO", response.data.data.portfolio)
          this.initializeAll()
        }
       })
    },
    deletePortfolio(){
     if(!this.awaitingDelete){return}
      this.$http.delete(`/portfolios/${this.awaitingDelete}/delete`)
      .then((response) => {
        if(response.data.success){
          this.$store.commit("productList/DELETE_PORTFOLIO", response.data.data.portfolioId)
        }
      })
    },
    confirmPortfolioDelete(slideId){
      this.awaitingDelete = slideId
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The Image will be removed permanently!',
        accept: this.deletePortfolio
      })
    },
    editPortfolio(slideId){
      this.sliderEditMode = true
      this.promptUpdate = true
      this.awaitingEdit = slideId
    },
    fileInputReader(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.imageHolderOnUpdate = e.target.result
        }
        this.sliderEditMode ?
            this.fileEditSlider = input.target.files[0] : this.fileEditPrimary = input.target.files[0]
        reader.readAsDataURL(input.target.files[0])
      }
    },
    removeImage(){
      this.$refs.fileInputUpload.value = ""
      this.imageHolderOnUpdate = ""
      this.fileEditSlider = this.fileEditPrimary = null
    },
    initializeAll(){
      this.popupSlideUploader = this.isVideoFile = this.promptUpdate = false
      if(this.$refs.fileInputUpload){
       this.$refs.fileInputUpload.value = ""
      }
      this.imageHolderOnUpdate = this.awaitingDelete = this.awaitingEdit = ""
      this.fileEditSlider = this.fileEditPrimary = null
    }
  }
}
</script>

<style scoped>
.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 60%;
    width: 32px;
    height: 32px;
    z-index: 10;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.64);
    background-size: 25px 25px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.cool-lightbox{
  z-index: 99999999999 !important;
}
.swiper-slide .subtitle {
  font-size: 21px;
  position: absolute;
  top: 5px;
  left: 10px;
}

.list-settings{
  position: absolute;
  top: 0;
  left: 0;
}

.list-settings li button{
  background:#ffffff82;
}
.swiper .play-button{
  position: absolute;
  top:43%;
  left:43%;
  background-color: rgba(0,0,0,.3);
  border-radius: 19px;
  line-height: 1;
  border: 2px solid #70748a;
  padding: 1rem 1rem;
  position: absolute;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.swiper .play-button:hover{
  background-color: rgba(0,0,0,.5);
}

</style>
