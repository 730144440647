<template>
<form>
    <div class="vx-row">
        <div class="vx-col lg:w-3/5 w-full">
            <vs-input v-validate="{ required: true}" label="Attribute name *" 
            placeholder="Attribute name" name="item_name" v-model="data.name" class="w-full" />
            <span class="text-danger text-sm"  v-show="errors.has('item_name')">{{ errors.first('item_name') }}</span>
        </div>
        <div class="vx-col lg:w-2/5 w-full lg:mt-0 mt-5">
            <vs-select autocomplete  v-validate="'required'" v-model="data.type" name="product" 
                placeholder="--Select Type--" label="Type*" class="select-large w-full">
                <vs-select-item key="0" value="check" text="Check" />
                <vs-select-item key="1" value="input" text="Input" />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
    </div>
    <vs-button class="ml-auto mt-2" @click="editMode? updateItem(): createItem()"
     size="small">{{editMode? 'Update':'Create'}}</vs-button>
</form>
</template>

<script>
export default {
    props:{
        attribute:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                product_id: this.$route.params.productId,
                name: "",
                type: ""
            },
        }
    },
    methods:{
        createItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/attributes/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/ADD_ATTRIBUTE', response.data.data.attribute)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/attributes/${this.data.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/UPDATE_ATTRIBUTE', response.data.data.attribute)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = {...this.attribute}
        }
    }
}
</script>

<style>

</style>