
<template>
  <div id="upload-alphabet">
    <vx-card subtitle="Product Image" class="card-reduce-top-pad">
      <template slot="actions">
        <vs-dropdown>
            <a class="flex items-center" href="#">
              <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
            </a>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="popupUploadImage = true;">
                Update
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
      </template>
      <img :src="absoluteUrl(product.image)" alt="content-img" class="responsive rounded-lg">
  </vx-card>
    <vs-popup class="holamundo" title="Update Product Image" :active.sync="popupUploadImage">
        <div>
          <vue-dropzone id="dropzone" ref="productImageUploadDrop" :use-custom-slot="true" 
            :maxFiles="1" :maxFilesize="20" acceptedFiles="image/*,.webp,.gif">
              <div class="needsclick m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                <h4 class="mb-2">Upload File or Drop file</h4>
                <p> Dimension should be <code>500px</code> x <code>500px</code></p>
              </div>
          </vue-dropzone>
        </div>
        <div class="flex flex-wrap justify-end items-center mt-5">
            <vs-button type="filled" @click.prevent="uploadFile()" class="mr-6">Update</vs-button>
        </div>
    </vs-popup>
</div>
</template>

<script>
import VueDropzone from "@/components/VueDropzone.vue";
export default {
  name: "product-image-update",
  components:{
    VueDropzone
  },
  data() {
    return {
      popupUploadImage:false,
    }
  },
  computed:{
    product(){
      return this.$store.state.productList.product;
    }
  },
  methods: {
    uploadFile() {
        if(!this.collectFiles().length){ this.alertError("Image file is required"); return;}
        const formData = new FormData()
        formData.append('image', this.collectFiles()[0])
        this.$vs.loading({color: "#444", type: "sound"})
        this.$http.post(`/products/${this.$route.params.productId}/update-image`,formData)
          .then((response)=> {
          this.$vs.loading.close()
          if(response.data.success){
            this.removeFiles()
            this.$store.dispatch("productList/fetchProduct", this.$route.params.productId)
          }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.productImageUploadDrop){
        arrafile = [...this.$refs.productImageUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    removeFiles() {
      this.$refs.productImageUploadDrop.removeAllFiles();
      this.popupUploadImage = false
    },
  }
}

</script>

