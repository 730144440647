<template>
 <form>
    <div class="vx-row">
        <div class="vx-col mb-5 w-full">
            <label class="text-sm"> Write Review </label>
                <vs-textarea label="Write Review" v-validate="{ required: true }" v-model="data.content" rows="6" name="content" />
                <span class="text-danger text-sm" v-show="errors.has('content')">{{ errors.first('content') }}</span>
                <div class="mt-3 flex justify-between items-center">
                <div>
                    <star-rating :rating="data.rating"
                     @rating-selected="setRating" :star-size="40" ></star-rating>
                </div>
            </div>
        </div>
        <div class="vx-col mb-5 w-full">
            <vs-input label="Client Name *" v-validate="{ required: true }" placeholder="Reviewer's Name" name="reviewer_name" v-model="data.reviewer_name" class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('reviewer_name')">{{ errors.first('reviewer_name') }}</span>
        </div>
        <div class="vx-col mb-5 w-full">
            <label class="text-sm"> Country </label>
            <v-select label="name"  name="country"
            v-model="data.country_id" :reduce="item => item.id" placeholder="--select country--" :closeOnSelect="true" :options="countries"/>
        </div>
        <div class="vx-col mb-5 w-full">
            <label class="text-sm">Review Date</label>
            <flat-pickr v-model="data.reviewed_at" :config="{ dateFormat: 'd F Y' }" class="w-full" placeholder="Publish Date" name="reviewed_at" />
        </div>
        <div class="vx-col mt-5 w-full">
            <vue-dropzone id="reviewImageUploadDrop" ref="reviewImageUploadDrop"
                :use-custom-slot="true" :maxFiles="1" :maxFilesize="200"
                acceptedFiles="image/*,.webp">
                <div class="needsclick m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                <h4 class="mb-2"> {{editMode? 'Add to update design':'Upload Design image'}}</h4>
                <p> Dimension should be <code>800px</code> x <code>600px</code></p>
                </div>
            </vue-dropzone>
        </div>
    </div>
    <vs-button class="ml-auto mt-4" @click.prevent="editMode ? updateItem(): createItem()" size="small">{{editMode ? 'Update':'Submit'}}</vs-button>
</form>
</template>

<script>
import StarRating from 'vue-star-rating'
import flatPickr from 'vue-flatpickr-component'
import VueDropzone from "@/components/VueDropzone.vue";

export default {
    components:{
        flatPickr,
        StarRating,
        VueDropzone
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                product_id: this.$route.params.productId,
                rating: 0,
                content: "",
                reviewer_name: "",
                reviewed_at: null,
                category: "PRODUCT",
                country_id:"",
            },
        }
    },
    computed: {
        countries(){
            return this.$store.state.countries
        },
    },
    methods:{
        setRating: function(rating) {
            this.data.rating = rating;
        },
        createItem(){
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                if(!this.collectFiles().length){ this.alertError("Design file is required"); return;}
                let formData = new FormData()
                formData.append('design_file', this.collectFiles()[0])
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post('/reviews/create', formData)
                .then(response => {
                    this.$vs.loading.close()
                    if(response.data.success){
                        this.$store.commit('productList/ADD_PAGE_REVIEW', response.data.data.review)
                        this.resetForm()
                    }
                })
                
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                let formData = new FormData()
                if(this.collectFiles().length){
                    formData.append('design_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post(`/reviews/${this.editItem.id}/update`, formData)
                .then(response => {
                    this.$vs.loading.close()
                    if(response.data.success){
                        this.$store.commit('productList/UPDATE_PAGE_REVIEW', response.data.data.review)
                        this.resetForm()
                    }
                })
            })
        },
        collectFiles(){
            var arrafile = [];
            if(this.$refs.reviewImageUploadDrop){
                arrafile = [...this.$refs.reviewImageUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        },
        patchUpdatables(){
            let allowed = Object.keys(this.data);
            return Object.keys(this.editItem)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.editItem[key];
                    return obj;
                }, {});
        }
    },
    mounted(){
        if(this.editMode){
            this.data = JSON.parse(JSON.stringify(this.patchUpdatables()))
        }
    }
}
</script>

<style>

</style>