<template>
<form>
    <div class="vx-row">
        <div class="vx-col lg:w-3/5 w-full">
            <vs-input v-validate="{ required: true}" label="Industry name *" 
            placeholder="Industry name" name="item_name" v-model="data.name" class="w-full" />
            <span class="text-danger text-sm"  v-show="errors.has('item_name')">{{ errors.first('item_name') }}</span>
        </div>
        <div class="vx-col lg:w-2/5 w-full lg:mt-0 mt-5">
            <label for="" class="vs-input--label">Industry slug </label>
            <select v-model="data.slug"
                class="form-select mb-3" placeholder="--select slug-">
                <option selected value="" >--select slug--</option>
                <option v-for="(dPro, iIndex) in products" :key="iIndex"
                :value="dPro.slug" >{{dPro.name}}</option>
            </select>
            <span class="text-danger text-sm" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
        </div>
    </div>
    <vs-button class="ml-auto mt-2" @click="editMode? updateItem(): createItem()"
     size="small">{{editMode? 'Update':'Create'}}</vs-button>
</form>
</template>

<script>
export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                product_id: this.$route.params.productId,
                name: "",
                slug: ""
            },
        }
    },
    computed: {
        products(){
            return this.$store.state.productList.products.map((p) => {return {id: p.id, name: p.name, slug: p.slug}})
        },
    },
    methods:{
        createItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/industries/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/ADD_INDUSTRY', response.data.data.industry)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/industries/${this.editItem.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/UPDATE_INDUSTRY', response.data.data.industry)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = {...this.editItem}
        }
    }
}
</script>

<style>

</style>